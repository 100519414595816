import React from "react";
import { Router } from "@reach/router";
import { Dashboard, Layout, Login, PrivateRoute, Profile } from "~components";

const App = () => (
  <Layout>
    <Router basepath="/app">
      <Login path="/login" />

      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/profile" component={Profile} />
    </Router>
  </Layout>
);

export default App;
